import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Wizard from '../views/Wizard.vue'
import Callback from "../components/Callback.vue"

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/wizard',
    name: 'wizard',
    component: Wizard
  },
  {
    path: '/callback',
    name: 'callback',
    component: Callback
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
