<template>
    <div class="background">
        <div class="modal" >
            <div>
                <h1>{{collection.collection}}</h1>
                <p> Important: You can edit these in the future whenever you want to. If you would like to put in your specific dates, feel free to do so or accept the defaults.</p>

                <label>What will be the name we use to identify which {{collection.collection}} these go to?</label>
                <input type="text" v-model="subject" placeholder="Nickname">
                <span class="error" v-if="subjectError">{{subjectError}}</span> 

                <hr>
                <div class="tablediv">
                    <div v-if="loading">
                        <h2>Loading...</h2>
                    </div>
                    <div class="error_div" v-if="load_error">
                        <h1>{{load_error}}</h1>
                    </div>
                    <table v-if="!load_error">
                        <tr v-for="task in tasks" class="task" :key="task">
                            <td>
                                <span id="recommend" @click.prevent="recommend(task)" :class="{selected: !task.recommend}" class="material-icons">{{task.recommend_icon}}</span>
                            </td>
                            <td>
                                <span class="material-icons">{{task.icon}}</span>
                            </td>
                            <td>
                                <div id="div" class="description">
                                    <strong>{{task.shortDesc}} for {{subject}}.<br></strong>
                                    <label>Notifications are {{task.cadence}} in the </label>
                                    <select v-model="task.notificationtime">
                                        <option value="morning">Morning</option>
                                        <option value="lunch">Lunch</option>
                                        <option value="afternoon">Afternoon</option>
                                        <option value="night">Night</option>
                                    </select>
                                    <label> time.</label>
                                </div>
                            </td>
                            <td>
                                <div id="duedate">
                                    <label >Due: </label>
                                    <input  type="text" v-model="task.duedate" @focusout="checkDuedate(task)">
                                    <span class="error" v-if="task.dateError"><br>{{task.dateError}}</span> 
                                </div>
                            </td>
                            <td>
                                <div  id="notification-type">
                                    <label > SMS:</label>
                                    <input type="checkbox" value="SMS" v-model="task.notificationmethod">
                                    <label > E-mail:</label>
                                    <input type="checkbox" value="email" v-model="task.notificationmethod">
                                </div>
                            </td>
                        </tr>
                    </table><br>
                    <hr>
                    <button class="back" @click.prevent="goBack">Back</button>
                    <button @click.prevent="saveDetails(tasks)">Next</button>
                </div>
            </div>
        </div>
    </div>
  
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue'
  export default defineComponent({
      props: {
        collection: Object,
        UUID: String
      },
      setup (props ,{emit}) {
        //Log In
        const date = new Date()
        const start_date = ref()
        const end_date = ref()
        const tasks = ref([])
        const error = ref([])
        const subjectError = ref('')
        const subject = ref('')
        const load_error = ref()
        const loading = ref(true)
        
        const next_year = date.getFullYear() + 2
        start_date.value = date.getFullYear() + '-01-01'
        end_date.value = next_year + '-01-01'

        const load = async () => {
            try {
                let data = await fetch('/api/ListTemplateTasks', 
                    { 
                    method: 'POST', 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json' 
                    },
                    body: JSON.stringify({ 'collection': props.collection.collection})
                })
                if (!data.ok) {
                    throw Error('API Call didnt work')
                    
                }
                tasks.value = await data.json()
                for (let task in tasks.value) {
                    if (tasks.value[task].recommend === true){ tasks.value[task].recommend_icon = 'remove' } else { tasks.value[task].recommend_icon = 'add'}
                    if (tasks.value[task].collection === 'Home'){ tasks.value[task].icon = 'house'}
                    if (tasks.value[task].collection === 'Vehicle'){ tasks.value[task].icon = 'directions_car'}
                    if (tasks.value[task].collection === 'Human'){ tasks.value[task].icon = 'person'}
                    if (tasks.value[task].collection === 'Pet'){ tasks.value[task].icon = 'pets'}
                }
                loading.value = false
                //console.log('loading = ' + loading.value)
                //console.log(tasks.value)
            }catch (err) {
                error.value = err.message
                console.log(error.value)
                load_error.value = 'Failed to talk to server :( Please try again later'
            }
        }
        load()

        const checkDuedate = (f) => {
            //console.log('checking DueDate')
            f.dateError = f.duedate.match(/^[0-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]$/) ? '': 'Due-Date must be Real Date and of format YYYY-MM-DD'
        }

        const recommend = (t) => {
            t.recommend = !t.recommend
            //console.log(t.recommend)
            //console.log(tasks.value)
            if(t.recommend == true){ 
                t.recommend_icon = 'remove'
            }
            if(t.recommend == false){ 
                t.recommend_icon = 'add'
            }     
        }

        const goBack = async () => {
            //Load list tasks for customer
            emit('back')
        }

        const saveDetails = (task_list) => {
            //console.log('start save')
            //console.log(task_list)
            
            if ( subject.value )
            {
                //console.log('subject set')
                //console.log(task_list)
                for(let task in task_list){
                    //console.log(task)
                    //console.log(task_list[task])
                    task_list[task].subject = subject.value
                    //console.log(task_list[task])
                }
                
                //Emit the list of tasks back to Wizard.vue
                emit('next', task_list)
            }else {
                subjectError.value = 'Nickname must be set.'

            }

        }
  
  
  
      return{saveDetails, goBack, checkDuedate, tasks, error, subjectError, subject, load_error, recommend, loading}
      }
      
  
  })
  </script>
  
  <style scoped>
  
  * {
    vertical-align: top;
  }
    .tablediv {
        align-items: center;
        text-align: center;
        overflow-y: scroll;
        overflow-x: scroll;
        height: 70vh;
    }

    table {
        display: inline-block;
        text-align: left;
    }

    table td {
        padding-top: 10px;
    }

    #duedate {
        padding-top: 10px;
    }

    #duedate label {
        padding-left: 5px;
    }

    #notification-type {
        padding-top: 10px;
    }

    #notification-type label {
        padding-left: 5px;
    }
    .description {
        max-width: 520px;
    }

    #recommend {
        cursor: pointer;
    }

    .selected.material-icons {
        color: #CA562A;
    }

    form {
        text-align: left;
    }

    input {
        outline: 1px;
        padding-left: 5px;
        padding-right: 5px;
    }

    select {
        outline: 1px;
        border: 1px;
        border-style: solid;
        border-color: black;
        padding-right: 5px;
    }

    input[type='text'] {
        border: 1px;
        border-style: solid;
        border-color: black;
        width: 70px;
    }

    input[type='checkbox'] {
        padding-top: 5px;
    }
  
    label {
        padding-right: 5px;
    }
  
    .modal {
        max-width: 900px;
        background: white;
        border-radius: 10px;
        z-index: 1000;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        height: 80vh;
        text-align: left;
    }

    .background {
        text-align: center;
        align-items: center;
    }

    h1 {
        text-align: center;
    }

    @media (max-height: 520px) {
        .tablediv {
            height: 60vh;
        }
    }

    @media (max-width: 520px) {
        .tablediv {
            height: 60vh;
        }

    }


  </style>