<template>
    <div class="backdrop" @click.self="closeProfile">
        <div class="modal" >
            <span class="material-icons filters">person</span>
            <h1>Profile</h1>
            <table>
                <tr><td class="table_label">Name:</td><td class="table_value">{{ name }}</td></tr>
                <tr><td class="table_label">E-mail:</td><td class="table_value">{{ email }}</td></tr>
                <tr v-if="country"><td class="table_label">Country:</td><td class="table_value">{{ country }}</td></tr>
                <tr><td class="table_label">Sub:</td><td class="table_value">Beta</td></tr>
            </table>
            <a href="https://littlereminders.b2clogin.com/littlereminders.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_editprofile1&client_id=d0ac88ad-a769-4964-98f8-22e0ac8df2ca&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.little-reminders.com%2Fcallback&scope=openid&response_type=id_token&prompt=login">Edit Profile</a>
        </div>
    </div>
</template>

<script>
import jwt_decode from "jwt-decode"
import { defineComponent, ref } from 'vue'
export default defineComponent({
    setup (props,{emit}) {
        const ID_TOKEN = ref()
        const test_json = ref()
        const name = ref()
        const email = ref()
        const country = ref()

        // Get token from local storage
        ID_TOKEN.value = localStorage.getItem("id_token")

        try {
            test_json.value = jwt_decode(ID_TOKEN.value)
        } catch (err) {
                console.log(err)
        }
        
        email.value = test_json.value["emails"][0]
        name.value = test_json.value["name"]
        country.value = test_json.value["country"]


        const closeProfile = () => {
            emit('closeProfile')
        }

    return{closeProfile, email, name, country}
    }
    

})
</script>

<style scoped>
    ul {
        list-style: none;
        text-align: left;
    }
    .modal {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        width: 300px;
        padding: 20px;
        top: 100px;
        background: white;
        border-radius: 10px;

    }

    .table_label {
        text-align: right;
        padding-right: 5px;
    }

    .table_value {
        text-align: left;
    }
    .backdrop {
        top: 0;
        position: fixed;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
    }

    @media (max-height: 520px) {
        .modal {
            top: 10px;
        }
    }

</style>