<template>
    <div>
        <h2>Completing login...</h2>
    </div>
</template>

<script>
    import {useRouter} from 'vue-router'
    import jwt_decode from "jwt-decode"
    import {ref} from 'vue'
    export default {
        
        setup () {
            const router = useRouter()
            const route = window.location.href
            const id_token = route.replace(/^.*id_token=/,"")
            const test_json = ref()
            //localStorage.clear()
            localStorage.setItem("id_token", id_token)
            try {
                test_json.value = jwt_decode(id_token)
                
            } catch (err) {
                console.log(err)
            }
            
            localStorage.setItem("email", test_json.value["emails"][0])
            localStorage.setItem("UUID", test_json.value["sub"] )
            localStorage.setItem("nbf", test_json.value["nbf"])
            localStorage.setItem("exp", test_json.value["exp"])

            const check_storage = () => {
                let email = localStorage.getItem("email")
                let UUID = localStorage.getItem("UUID")
                console.log("login success")
                console.log("user: " + email)
                console.log("UUID " + UUID)
            }
            check_storage()

            try {
                localStorage.getItem("UUID")
                window.location.replace("/")
            }catch (err) {
                console.log(err)
            }

            return {}
        }
    }
</script>