<template>
    <div class="backdrop">
        <div class="summary_modal">
            <h1>Summary...</h1>
            <p>Confirm all data here. Click back to edit.</p>
            <hr>
            <div class="tablediv">
                    <table>
                        <tr v-for="task in tasks" class="task" :key="task">
                            <td>
                                <span class="material-icons">{{task.icon}}</span>
                            </td>
                            <td id="description">
                                <div>
                                    <strong><label >{{task.shortDesc}} for {{task.subject}}.</label></strong><br>
                                    <label>Notifications are {{task.cadence}} in the </label>
                                    <select v-model="task.notificationtime">
                                        <option value="morning">Morning</option>
                                        <option value="lunch">Lunch</option>
                                        <option value="afternoon">Afternoon</option>
                                        <option value="night">Night</option>
                                    </select>
                                    <label> time.</label>
                                </div>
                            </td>
                            <td>
                                <div id="div" class="duedate">
                                    <label >Due: </label>
                                    <input  type="text" v-model="task.duedate" @focusout="checkDuedate(task)">
                                    <span class="error" v-if="task.dateError">{{task.dateError}}</span> 
                                </div>
                            </td>
                            <td>
                                <div id="div" class="notification-type">
                                    <label >SMS</label>
                                    <input type="checkbox" value="SMS" v-model="task.notificationmethod">
                                    <label >E-mail</label>
                                    <input type="checkbox" value="email" v-model="task.notificationmethod">
                                </div>
                            </td>
                                
                        </tr>
                    </table><br>
                    <button @click="goBack" class="back">Back</button>
                    <button @click="saveDetails">Confirm</button>
                </div>
            <hr>

        </div>
    </div>
</template>

<script>
import {defineComponent, ref} from 'vue'
import {useRouter} from 'vue-router'

export default defineComponent({
    props: {
        subject_list: Array,
        UUID: String,
    },
    setup(props,{emit}) {
        const router = useRouter()
        const tasks = ref({})
        const error = ref()

        const load = () => {
            // Take the subject_list and break it down into a useable format
            let x = 0
            for(let a in props.subject_list){
                //Iterate through subjects
                for (let b in props.subject_list[a]['tasks']){
                    // Iterate through tasks listed under each subject
                    tasks.value[x] = {
                        "UUID": props.UUID,
                        "taskid": props.subject_list[a]['tasks'][b].taskid,
                        "shortDesc": props.subject_list[a]['tasks'][b].shortDesc,
                        "collection": props.subject_list[a]['tasks'][b].collection,
                        "duedate": props.subject_list[a]['tasks'][b].duedate,
                        "subject": props.subject_list[a]['tasks'][b].subject,
                        "notificationmethod": props.subject_list[a]['tasks'][b].notificationmethod,
                        "notificationtime": props.subject_list[a]['tasks'][b].notificationtime,
                        "lastcompleted": []
                    }
                    if (tasks.value[x].collection === 'Home'){ tasks.value[x].icon = 'house'}
                    if (tasks.value[x].collection === 'Vehicle'){ tasks.value[x].icon = 'directions_car'}
                    if (tasks.value[x].collection === 'Human'){ tasks.value[x].icon = 'person'}
                    if (tasks.value[x].collection === 'Pet'){ tasks.value[x].icon = 'pets'}
                    x++
                }
            }
        }


        load()
        const goBack = async () => {
            //Load list tasks for customer
            console.log('back')
            emit('back')
        }

        const saveDetails = async () => {
            //Load list tasks for customer
            try {
                console.log(tasks.value)
                let data = await fetch('/api/LoadTemplate', 
                { 
                    method: 'POST', 
                    headers: { 
                    'Content-Type': 'application/json' 
                    },
                    body: JSON.stringify(tasks.value)
                })
                if (!data.ok) {
                toast_text.value = 'Failed'
                throw Error('API Call didnt work')
                }
                emit('reload')
                //console.log(tasks.value)
            }catch (err) {
                error.value = err.message
                console.log(error.value)
            }

            router.push("/")
           
        }
        return {saveDetails, goBack, tasks, error}
    }

})
</script>

<style scoped>

* {
    vertical-align: top;
  }
    .summary_modal {
        max-width: 900px;
        background: white;
        border-radius: 10px;
        z-index: 1000;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        height: 80vh;
        text-align: left;
        overflow-x: scroll;
    }

    .background {
        background: white;
        width: 100%;
        z-index: 100;
        text-align: center;
    }

    .duedate {
        padding-top: 10px;
    }

    .notification-type {
        padding-top: 10px;
    }
    
    .tablediv {
        align-items: center;
        text-align: center;
        max-height: 70vh;
        overflow-y: scroll;
        overflow-x: scroll;
    }

    table {
        display: inline-block;
        text-align: left;
    }

    table td {
        padding-top: 10px;
    }

    form {
        text-align: left;
    }

    input {
        outline: 1px;
        padding-left: 5px;
        padding-right: 5px;
    }

    select {
        outline: 1px;
        border: 1px;
        border-style: solid;
        border-color: black;
        padding-right: 5px;
    }

    input[type='text'] {
        border: 1px;
        border-style: solid;
        border-color: black;
        width: 70px;
    }

    input[type='checkbox'] {
        padding-top: 5px;
    }
  
    label {
        padding-right: 5px;
    }
</style>