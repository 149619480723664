<template>
    <div class="home">
      <div v-if="error.length">
      <p>Looks like we ran into a little problem.</p>
      <p>Try Logging out and back in or try again later...</p>
      </div>
    </div>
    <div v-if="showDetails">
        <detailView @closeDetails="openDetails" :ID_TOKEN="ID_TOKEN" :taskid="taskid"/>
    </div>
    <div class="taskbackground" v-if="!error.length">
        <h1>To Do:</h1>
        <div class="formdiv" @reload="load">
            <form v-if="UUID">
                <span class="material-icons filters" v-if="!filters_on" @click="filters_on = !filters_on">filter_alt</span>
                <span class="material-icons filters" v-if="filters_on" @click="filters_on = !filters_on">filter_alt_off</span>
                <div class="filters" v-if="filters_on">
                    <label>Date Range: </label>
                    <select v-model="date_range" @change="set_date(date_range)">
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                        <option value="all">All</option>
                    </select>
                    <label>Type: </label>
                    <select v-model="collection_filter" @change="filter()">
                        <option value="all">All</option>
                        <option value="Home">Home</option>
                        <option value="Vehicle">Vehicle</option>
                        <option value="Human">Human</option>
                        <option value="Pet">Pet</option>
                    </select>
                </div>
                <hr>
                <div v-if="loading"><h2>Loading...</h2></div>
                <div class="table">
                    <table :class="{edit: edit_mode}">
                        <tr v-for="task in tasks" class="task" :key="task" :class="{isdone: task.done, hover: task.hover}" @mouseenter="task.hover = true" @mouseleave="task.hover = false" >
                            <td class="donebutton">
                                <button id="donebutton" :class="{isdone: task.done}" @click.prevent="markDone(task)">{{task.done_text}}</button>
                            </td>
                            <td>
                                <span class="material-icons">{{task.icon}}</span>
                            </td>
                            <td id="description">
                                <label :class="{isdone: task.done}" @click="openDetails(task.taskid)">{{task.shortDesc}} for {{task.subject}}.</label>                  
                            </td>
                            <td id="duedate">
                                <label :class="{isdone: task.done}">Due: </label>
                                <span v-if="!edit_mode" :class="{isdone: task.done}">{{task.duedate}}</span>
                                <input v-if="edit_mode" :class="{isdone: task.done}" type="text" v-model="task.duedate" @focusout="checkDuedate(task)">
                                <span class="error" v-if="task.dateError"><br>{{task.dateError}}</span> 
                            </td>
                            <td id="notification-type" v-if="edit_mode">
                                <label :class="{isdone: task.done}">SMS</label>
                                <input type="checkbox" value="SMS" v-model="task.notificationmethod">
                                <label :class="{isdone: task.done}">E-mail</label>
                                <input type="checkbox" value="email" v-model="task.notificationmethod">
                            </td>
                        </tr>
                    </table>
                </div>
                <hr>
                <button id="editbutton" @click.prevent="reload_formdiv">Edit</button>
                <button id="submit" :class="{submit_hide: !edit_mode}" @click.prevent="saveChange">Save</button>  
                <div class="compliment">
                    <h2>{{compliment}}</h2>
                </div>
            </form>
            <h2 v-if="!UUID">Looks like you aren't logged in!</h2>
        </div>
    </div>
  </template>
  
  <script>
  import {ref} from 'vue'
  import detailView from '../components/detailView.vue'
  import {useRouter} from 'vue-router'
  export default {
    name: 'Home',
    setup() {
        //Log In
        const UUID = ref()
        const ID_TOKEN = ref()
        const tasks = ref([])
        const raw_tasks = ref([])
        const error = ref([])
        const error2 = ref([])
        const taskid = ref()
        const date = new Date()
        const date_range = ref('month')
        const collection_filter = ref('all')
        const re_load = ref(true)
        const compliment = ref('')
        const edit_mode = ref(false)
        const showDetails = ref(false)
        const filters_on = ref(false)
        const done_text = ref('Done')
        const router = useRouter()
        const loading = ref(true)
        const reload = ref(false)
        const start_date = ref()
        const end_date = ref()

        const next_month = (date.getMonth() + 2)
        
        const first_thing = async () => {
            try {
                let data2 = await fetch('/api/compliment',{ 
                    method: 'GET', 
                    headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json' 
                    }
                })
                if (!data2.ok) {
                throw Error('API Call didnt work')
                }
                compliment.value = await data2.text()
                //console.log(compliment)
            }catch (err) {
                error2.value = err.message
                console.log(error2.value)
            }
            // Before we even try loading everything and showing stuff, lets check if they are new and route them to the wizard if they are.
            const next_year = date.getFullYear() + 2
            start_date.value = date.getFullYear() + '-01-01'
            end_date.value = next_year + '-01-01'
            let start_list = {}
            try {
                //console.log("first load to see if items are in DB")
                //console.log('UUID: ' +  UUID.value + " start_date: " + start_date.value + " end_date: " + end_date.value)
                let data = await fetch('api/ListFullTasks', 
                    { 
                    method: 'POST', 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json' 
                    },
                    body: JSON.stringify({ 'id_token': ID_TOKEN.value, "start_date": start_date.value, "end_date": end_date.value})
                    })
                if (!data.ok) {
                    throw Error('API Call didnt work')
                }
                start_list.value = await data.json()
                //console.log(start_list.value)
                
            }catch (err) {
                    error.value = err.message
                    console.log(error.value)
            }
            if(!start_list.value[0]){
                router.push("/wizard")
            }

        }
        
        //console.log(tasks.length)

        const load = async () => {
            // Check if token duedate is still accurate
            try {
                const nbf = localStorage.getItem("nbf")
                const exp = localStorage.getItem("exp")
                //console.log(exp)
                //console.log(nbf)
                //console.log(date.getTime()/1000)
                date.value = new Date()
                //console.log(date.getTime()/1000)

                //console.log(date.getTime()/1000)

                if ( date.getTime()/1000 > exp || date.getTime()/1000 < nbf ){
                    localStorage.clear()
                    window.location.replace("/")
                }
                //if ( date.value >= nbf && date.getUTCMilliseconds <= exp  )

            } catch (err) {
                //if token date is not accurate or we don't have values, clear storage and reload the page
                console.log(err)
            }

            //console.log('running load')
            console.log('UUID: ' +  UUID.value + " start_date: " + start_date.value + " end_date: " + end_date.value)
            //Check if filters are added 
            //if(date_range.value != "week"){ re_load.value = true}
            re_load.value = true

            if(re_load){
                //If we need to hit the API, then do it
                try {
                    let data = await fetch('/api/ListFullTasks', 
                        { 
                        method: 'POST', 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json' 
                        },
                        body: JSON.stringify({ "id_token": ID_TOKEN.value, "start_date": start_date.value, "end_date": end_date.value})
                        })
                    if (!data.ok) {
                        throw Error('API Call didnt work')
                    }
                    raw_tasks.value = await data.json()
                    for (let task in raw_tasks.value) {
                        if (raw_tasks.value[task].collection === 'Home'){ raw_tasks.value[task].icon = 'house'}
                        if (raw_tasks.value[task].collection === 'Vehicle'){ raw_tasks.value[task].icon = 'directions_car'}
                        if (raw_tasks.value[task].collection === 'Human'){ raw_tasks.value[task].icon = 'person'}
                        if (raw_tasks.value[task].collection === 'Pet'){ raw_tasks.value[task].icon = 'pets'}
                        raw_tasks.value[task].done_text = 'Done'
                    }
                    loading.value = false
                }catch (err) {
                    error.value = err.message
                    console.log(error.value)
                }
                re_load.value = false
            }

            filter()
        }
        

        const filter = () => {
            tasks.value = []
            if (collection_filter.value != "all"){
                //console.log(raw_tasks.value)
                let counter = 0
                for(let task in raw_tasks.value){
                    //if task's collection is not in the listed of selected collections, filter it out of tasks list
                    if(collection_filter.value.includes(raw_tasks.value[task].collection))
                    { 
                        //console.log(raw_tasks.value[task])
                        //console.log(raw_tasks.value[task].collection + ' selected')
                        tasks.value[counter] = raw_tasks.value[task]
                        counter++
                        //console.log(tasks.value[task])
                    } 

                }
                //console.log(tasks.value)
            } else {
                tasks.value = raw_tasks.value
            }
            
        }
  
        const set_date = (date_range) => {
            if ( date_range === 'week' ) {
                //console.log('set to weekly view')
                var prevMonday = new Date()
                prevMonday.setDate(date.getDate() - (date.getDay() + 6) % 7)
                start_date.value = prevMonday.getFullYear() + '-' + ( "0" + (prevMonday.getMonth() + 1 )).slice(-2) + '-' + ( "0" + prevMonday.getDate()).slice(-2)
                //console.log(start_date.value)
                var enddate_var = new Date()
                var end_date_date = []
                enddate_var.setDate(prevMonday.getDate() + 6)
                end_date_date[2]=enddate_var.getDate()
                // if end_date_Date is less than start_date then month has rolled
                //console.log(prevMonday.getDate() + " > " + end_date_date[2])
                if( prevMonday.getDate() > end_date_date[2]){
                    //Month numbers are array based so need to add 1 to get to correct number
                    end_date_date[1]=(prevMonday.getMonth() + 2)
                } else {
                    end_date_date[1]=prevMonday.getMonth() + 1
                }
                if (end_date_date[1] == 13){end_date_date[1] = 1}

                // if end_date_date is less than start_date then month has rolled
                if( end_date_date[1] < prevMonday.getMonth()){
                    end_date_date[0]=prevMonday.getFullYear() + 1
                } else {
                    end_date_date[0]=prevMonday.getFullYear()
                }
                
                end_date.value = end_date_date[0] + '-' + ("0" + end_date_date[1]).slice(-2) + '-' + ( "0" + end_date_date[2]).slice(-2)
                //console.log(end_date.value)
            } 
            if ( date_range === "month" ) {
                //console.log('set to monthly view')
                var next_month = date.getMonth() + 2
                start_date.value = date.getFullYear() + '-' + ( "0" + (date.getMonth() + 1)).slice(-2) + '-01'
                if (next_month == 13){
                    next_month = 1
                    var end_date_year = date.getFullYear() + 1
                } else {
                    var end_date_year = date.getFullYear()
                }
                end_date.value = end_date_year + '-' + ( "0" + next_month).slice(-2) + '-01'
            }
            if ( date_range === "year" ) {
                //console.log('set to yearly view')
                const next_year = date.getFullYear() + 1
                start_date.value = date.getFullYear() + '-01-01'
                end_date.value = next_year + '-01-01'
            }
            if ( date_range === "all" ) {
                //console.log('set to yearly view')
                const next_year = date.getFullYear() + 2
                start_date.value = date.getFullYear() + '-01-01'
                end_date.value = next_year + '-01-01'
            }
            load()
        }

        const markDone = async (task) => {
            task.done = !task.done
            if(task.done)
            { 
                //reverse the text of the Done button
                task.done_text = 'Undo'
                try {
                    //mark the task as Done
                    let data = await fetch('/api/UpdateTask', 
                    { 
                        method: 'POST', 
                        headers: { 
                        'Content-Type': 'application/json' 
                        },
                        body: JSON.stringify({ 'id_token': ID_TOKEN.value, 'taskid': task.taskid, 'api_action': 'done'})
                    })
                    if (!data.ok) {
                    throw Error('API Call didnt work')
                    }

                }catch (err) {
                    error.value = err.message
                    console.log(error.value)
                }
            }
            else{
                //reverse the text of the done button
                task.done_text = 'Done'
                try {
                    //Set the status of the task to done
                    let data = await fetch('/api/UpdateTask', 
                        { 
                        method: 'POST', 
                        headers: { 
                            'Content-Type': 'application/json' 
                        },
                        body: JSON.stringify({ 'id_token': ID_TOKEN.value, 'taskid': task.taskid, 'api_action': 'un-done'})
                        })
                    if (!data.ok) {
                        throw Error('API Call didnt work')
                    }
                }catch (err) {
                error.value = err.message
                console.log(error.value)
                }
            }
        }

        const reload_formdiv = () => {
            edit_mode.value = !edit_mode.value
            set_date(date_range.value)
        }

        const checkDuedate = (f) => {
            //console.log('checking DueDate')
            f.dateError = f.duedate.match(/^[0-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]$/) ? '': 'Due-Date must be Real Date and of format YYYY-MM-DD'
        }

        const openDetails = (task_id) => {
            taskid.value = task_id
            showDetails.value = !showDetails.value
        }

        const saveChange = async () => {
            const editted_tasks = { "action": "update"}
            //Load task list into proper format
            //console.log(tasks.value)
            for (let task in tasks.value){
                // Iterate through tasks
                //console.log(tasks.value[task])
                editted_tasks[task] = {
                    "UUID": UUID.value,
                    "_id": tasks.value[task].taskid,
                    "collection": tasks.value[task].collection,
                    "duedate": tasks.value[task].duedate,
                    "subject": tasks.value[task].subject,
                    "notificationmethod": tasks.value[task].notificationmethod,
                    "notificationtime": tasks.value[task].notificationtime,
                }
            }

            //Load list tasks for customer
            try {
                //console.log(editted_tasks)
                let data = await fetch('/api/LoadTemplate', 
                { 
                    method: 'POST', 
                    headers: { 
                    'Content-Type': 'application/json' 
                    },
                    body: JSON.stringify(editted_tasks)
                })
                if (!data.ok) {
                throw Error('API Call didnt work')
                }
                //console.log(tasks.value)
            }catch (err) {
                error.value = err.message
                console.log(error.value)
            }
        }

        const getUserInfo = () => {
            try {
                UUID.value = localStorage.getItem("UUID")
                ID_TOKEN.value = localStorage.getItem("id_token")
                if ( UUID.value != null ){
                    first_thing()
                    set_date(date_range.value)
                }
                //console.log(UUID.value)
            }catch (err) {
                console.log(err)
            }
        }
        getUserInfo()

        return {tasks, error, markDone, checkDuedate, showDetails, openDetails, taskid, UUID, start_date, end_date, date_range, set_date, load, collection_filter, filter, compliment, edit_mode, filters_on, done_text, saveChange, loading, reload, reload_formdiv, ID_TOKEN}
  
    },
    components: {
        detailView
    }
  }
  </script>
  
  <style scoped>
    * {
        vertical-align: top;
    }
    .material-icons { 
        font-size: 36px;
        margin-inline: 10px;
    }

    .home {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .filters {
        display: inline;
    }

    .material-icons.filters {
        font-size: 24px;
        cursor: pointer;
    }

    .formdiv {
        position: absolute;
        top: 80px;
        left:50%;
        transform: translateX(-50%);
        max-width: 970px;
        max-height: 72vh;
        margin-top: 10px;
        background: white;
        text-align: left;
        padding: 10px;
        border-radius: 10px;
    }

    .formdiv table {
        min-width: 690px;
    }

    table.edit {
        min-width: 760px;
    }

    form {
        padding-bottom: 20px;
    }

    .table {
        overflow-y: scroll;
        overflow-x: scroll;
        max-height: 60vh;
    }
    .submit_hide {
        visibility: hidden;
    }

    #submit {
        float: left;
        background: #CA562A;
    }

    #editbutton {
        float: right;
    }

    .compliment {
        padding-top: 30px;
        text-align: center;
    }

    #description {
        transform:translateY(25%);
        text-align: left;
        width: 475px;
    }

    #duedate {
        transform:translateY(25%);
        width: 170px;
    }

    #notification-type {
        width: 265px;
        transform:translateY(25%);
    }

    #donebutton {
        margin: 0;
        background: #207EC6;
    }

    .donebutton {
        width: 75px;
        padding: 0;
        margin: 0;
    }

    .task {
        display: block;
        max-width: 900px;
        margin: 20px;
        padding: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 10px;
        background: white;
        border-color: white;
        border-style: solid;
        border-width: 1px;
    }
    .task img {
        max-width: 30px;
        max-height: 40px;
        margin-inline: 5px;
    }

    input[type="checkbox"] {
        margin: 2px;
        margin-inline: 5px;        
    }

    .task input[type="text"] {
        width: 70px;
        border-style: solid;
        border-color: black;
        border-width: 1px;
        padding: 5px;
        border-radius: 5px;
        transform: translateY(-25%);
    }

    .task.hover {
        box-shadow: 6px 6px #888888;
        border: 1px solid;
    }

    .submit {
        text-align: right;
    }

    #donebutton.isdone {
        background: #CA562A;
    }
    label.isdone {
        text-decoration-line: line-through;
    }

    span.isdone {
        text-decoration-line: line-through;
    }

    input.isdone {
        text-decoration-line: line-through;
    }

    select.isdone {
        text-decoration-line: line-through;
    }

    @media (max-width: 760px) {
        .table {
            max-width: 100vw;
            max-height: 60vh;

        }
        .formdiv {
            max-width: 100vw;
            max-height: 80vh;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }
    }

    @media (max-width: 520px) {
        .table {
            max-width: 100vw;
            max-height: 60vh;

        }
        .formdiv {
            max-width: 100vw;
            max-height: 80vh;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }

        .compliment {
            padding-top: 14px;
        }

        .compliment h2 {
            font-size: 14px;
            font-weight: 700;
        }

    }

    @media (max-height: 520px) {
        .formdiv {
            top: 20vh;
            max-width: 100vw;
            max-height: 70vh;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 10px;
        }

        .table {
            max-width: 100vw;
            max-height: 50vh;
            overflow-y: scroll;
            overflow-x: scroll;
        }

        .compliment {
            padding-top: 8px;
        }
        .compliment h2 {
            font-size: 20px;
            font-weight: 700;
        }

    }
  </style>