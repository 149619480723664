<template>
  <div class="details">
    <div v-if="error.length">
    <p>Looks like we ran into a little problem.</p>
    <p>{{error}}</p>
    </div>
    <div v-if="tasks" class="backdrop" @click.self="closeDetails">
        <div class="modal" >
          <div v-if="showToast" class="toast">
            <h2>{{toast_text}}</h2>
          </div>
          <div v-if="!showToast">
            <h1>Detail View</h1>
            <p><strong>{{tasks.shortDesc}}</strong></p>
            <p>{{tasks.longDesc}}</p>
            <form @submit.prevent>
              <label>Subject:</label>{{tasks.subject}}<br>
              <label>Frequency:</label>{{frequency}}<br>
              <label>Notification Time:</label>
              <select v-model="tasks.notificationtime">
                <option value="morning">Morning</option>
                <option value="lunch">Lunch</option>
                <option value="afternoon">Afternoon</option>
                <option value="night">Night</option>
              </select><br>
              <label>Notification Type:</label>
              <label>SMS</label><input type="checkbox" value="SMS" v-model="tasks.notificationmethod">
              <label>E-mail</label><input type="checkbox" value="email" v-model="tasks.notificationmethod"><br>
              <label>Due Date:</label><input type="text" v-model="tasks.duedate" @focusout="checkDuedate(tasks)"><br>
              <span class="error" v-if="tasks.dateError">{{tasks.dateError}}<br></span>
              <label>Last Completed:</label>{{tasks.lastcompleted}}<br>

              <label>Provider:</label><input type="text" v-model="tasks.provider"><br>
              <label>Notes...</label><br>
              <textarea v-model="tasks.notes"></textarea><br>
            </form>
            <button v-if="!showToast" @click.prevent="saveDetails(tasks)">Save</button>
            <button v-if="!showToast" class="back" @click="closeDetails">Cancel</button>
          </div>
        </div>
    </div>
    <div v-else>Loading Details</div>
  </div>

</template>

<script>
import { defineComponent, ref } from 'vue'
export default defineComponent({
    props: {
      ID_TOKEN: String, 
      taskid: String
  },
    setup (props ,{emit}) {
      //Log In
  
      
      const tasks = ref([])
      const error = ref([])
      const ID_TOKEN = ref(props.ID_TOKEN)
      const taskid = ref(props.taskid)
      const frequency = ref('')
      const showToast = ref(false)
      const toast_text = ref('saving...')

      //console.log(UUID.value + ' ' + taskid.value)

      //console.log('attempting to show details')
      const load = async () => {
        try {
          let data = await fetch('/api/TaskDetail', 
            { 
              method: 'POST', 
              headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
              },
              body: JSON.stringify({ 'id_token': ID_TOKEN.value, "taskid": taskid.value})
            })
          if (!data.ok) {
            throw Error('API Call didnt work')
          }
          tasks.value = await data.json()
          //console.log(tasks.value)
        }catch (err) {
          error.value = err.message
          console.log(error.value)
        }
        const frequencies = ["weekly", "monthly", "quarterly", "bi-yearly", "yearly"]
        const tags_json = tasks.value["tags"]
        let tag = tags_json.filter(value => frequencies.includes(value))
        //console.log(tag)
        frequency.value = tag[0]
      }
      load()

      const closeDetails = () => {
          emit('closeDetails')
      }

      const saveDetails = async (task) => {
        showToast.value = true
        try {
          toast_text.value = 'saving...'
          setTimeout(() => showToast.value = false, 2000)
          setTimeout(() => closeDetails(), 2000)
          console.log("task"+ task.shortDesc)
          console.log("api_action"+ 'details')
          console.log("subject"+ task.subject)
          console.log("notificationtime"+ task.notificationtime)
          console.log("notificationmethod"+ task.notificationmethod)
          console.log("duedate"+ task.duedate)
          console.log("provider"+ task.provider)
          console.log("notes"+ task.notes)
          let data = await fetch('/api/UpdateTask', 
            { 
              method: 'POST', 
              headers: { 
                'Content-Type': 'application/json' 
              },
              body: JSON.stringify({ 
                "id_token": ID_TOKEN.value, 
                "taskid": taskid.value,
                "api_action": 'details',
                "subject": task.subject,
                "notificationtime": task.notificationtime,
                "notificationmethod": task.notificationmethod,
                "duedate": task.duedate,
                "provider": task.provider,
                "notes": task.notes
              })
            })
          if (!data.ok) {
            toast_text.value = 'Failed'
            throw Error('API Call didnt work')
          } else {
            console.log(taskid.value + " saved")
          }
                        
          emit('reload')
          //console.log(tasks.value)
        }catch (err) {
          error.value = err.message
          toast_text.value = 'Failed'
          console.log(error.value)
        }
      }

      const checkDuedate = (f) => {
          //console.log('checking DueDate')
          f.dateError = f.duedate.match(/^[0-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]$/) ? '': 'Due-Date must be Real Date and of format YYYY-MM-DD'
      }


      return{closeDetails, error, tasks, frequency, saveDetails, showToast, toast_text, checkDuedate}
    }
    

})
</script>

<style scoped>
  h1 {
    padding-bottom: 10px;
  }

  p {
    text-align: left;
    padding-bottom: 10px;
  }

  form {
    text-align: left;
  }

  label {
    line-height: 22px;
    margin-right: 10px;
  }

  input[type="checkbox"] {
    padding-inline: 5px;
  }

  input[type="text"] {
    border-style: solid;
    border-color: black;
    border-width: 1px;
    padding: 2px;
    border-radius: 5px;
  }

  textarea {
    border-style: solid;
    border-color: black;
    border-width: 1px;
    padding: 2px;
    border-radius: 5px;
    width: 100%;
    height: 80px;
  }

  select {
    outline: 1px;
    border: 1px;
    border-style: solid;
    border-color: black;
    padding-right: 5px;
  }

  .toast {
    z-index: 1000;
    width: 200px;
    padding: 20px;
    margin: 50px auto;
    background: #42b983;
    color: white;
    border-radius: 10px;
  }

  .modal {
      max-width: 400px;
      padding: 20px;
      margin: 100px auto;
      background: white;
      border-radius: 10px;
      z-index: 800;
  }
  .backdrop {
      top: 0;
      position: fixed;
      background: rgba(0,0,0,0.5);
      width: 100%;
      height: 100%;
      z-index: 100;
  }
</style>