<template>
    <div v-if="UUID">
    <div v-if="showfinalsummary">
        <showSummary @back="previousIndex" :subject_list="subject_list" :UUID="UUID" />
    </div>
    <div v-if="showStartSummary" :class="{dark: showStartSummary}" @click.self="showStartSummary = !showStartSummary">
        <div class="startSummary" @closeSubject="showStartSummary = !showStartSummary">
            <h1 style="text-align: center">{{summary_message}}</h1>
            <ul>
                <li><h2>{{homes}} Homes</h2></li>
                <li><h2>{{vehicles}} Vehicles</h2></li>
                <li><h2>{{humans}} Humans</h2></li>
                <li><h2>{{pets}} Pets</h2></li>
            </ul>
            <br>
            <button @click="showStartSummary = false" class="back">Back</button>
            <button @click="startSetup">Yes</button>
        </div>
    </div>
    <div v-if="showSubjectSetup" :key="subject_list_index">
        <subjectSetup @next="(tasks) => nextIndex(tasks)" @back="previousIndex" :UUID="UUID" :collection="collection" />
    </div>
    <div class="setup" v-if="showSetup">
        <h1>Let's get started!</h1>
        <h3>Choose what types of reminders you might need...</h3>
        <form class="taskForm">
        <table>
            <tr>
                <td><div class="option" :class="{selected: houseselected}" @click="houseselected = !houseselected" >
                    <span class="material-icons">house</span>
                    <h2>Home</h2>
                </div></td>
                <td><div class="option" :class="{selected: vehicleselected}" @click="vehicleselected = !vehicleselected">
                    <span class="material-icons">directions_car</span>
                    <h2>Vehicle</h2>
                </div></td>
                <td><div class="option" :class="{selected: humanselected}" @click="humanselected = !humanselected">
                    <span class="material-icons">person</span>
                    <h2>Human</h2>
                </div></td>
                <td><div class="option" :class="{selected: petselected}" @click="petselected = !petselected">
                    <span class="material-icons">pets</span>
                    <h2>Pet</h2>
                </div></td>
            </tr>
            <tr>
                <td><select id="homeselect" v-model="homes" v-if="houseselected">
                    <option value=1 selected>1</option>
                    <option value=2>2</option>
                    <option value=3>3</option>
                    <option value=4>4</option>
                    <option value=5>5</option>
                </select></td>
                <td><select id="vehicleselect" v-model="vehicles" v-if="vehicleselected">
                    <option value=1 selected>1</option>
                    <option value=2>2</option>
                    <option value=3>3</option>
                    <option value=4>4</option>
                    <option value=5>5</option>
                    <option value=6>6</option>
                    <option value=7>7</option>
                    <option value=8>8</option>
                    <option value=9>9</option>
                    <option value=10>10</option>
                </select></td>
                <td><select id="humanselect" v-model="humans" v-if="humanselected">
                    <option value=1 selected>1</option>
                    <option value=2>2</option>
                    <option value=3>3</option>
                    <option value=4>4</option>
                    <option value=5>5</option>
                    <option value=6>6</option>
                    <option value=7>7</option>
                    <option value=8>8</option>
                </select></td>
                <td><select id="petselect" v-model="pets" v-if="petselected">
                    <option value=1 selected>1</option>
                    <option value=2>2</option>
                    <option value=3>3</option>
                    <option value=4>4</option>
                    <option value=5>5</option>
                    <option value=6>6</option>
                    <option value=7>7</option>
                    <option value=8>8</option>
                </select></td>
            </tr>
        </table>
        </form>
        <button @click.prevent="nextStep" id="done" v-if="!showStartSummary">Done</button>
    </div>

    </div>
</template>

<script>
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import subjectSetup from '../components/subjectSetup.vue'
import showSummary from '../components/Summary.vue'

export default {
    setup() {
        const houseselected = ref(false)
        const vehicleselected = ref(false)
        const humanselected = ref(false)
        const petselected = ref(false)
        const homes = ref(1)
        const vehicles = ref(1)
        const humans = ref(1)
        const pets = ref(1)
        const showStartSummary = ref(false)
        const showSetup = ref(true)
        const summary_message = ref('')
        const showSubjectSetup = ref(false)
        const subject_list = ref({})
        const subject_list_index = ref(0)
        const len = ref(0)
        const router = useRouter()
        const collection = ref([])
        const showfinalsummary = ref(false)
        const UUID = ref()

        const getUserInfo = () => {
            try {
                UUID.value = localStorage.getItem("UUID")
                if ( UUID.value == null ){
                    window.location.replace("/")
                }
                //console.log(UUID.value)
            }catch (err) {
                console.log(err)
            }
        }
        getUserInfo()

        const nextStep = () => {
            if (houseselected.value === false){ homes.value = 0 }
            if (vehicleselected.value === false){ vehicles.value = 0 }
            if (humanselected.value === false){ humans.value = 0 }
            if (petselected.value === false){ pets.value = 0 }
            if ( (homes.value + vehicles.value + humans.value + pets.value) === 0 ){
                summary_message.value = 'You didn\'t select anything...'
            } else {
                summary_message.value = 'Does this look right?'
            }
            //create array with collection for name in each slot for the number of them selected
            // Eg. <"home","vehicle", "vehicle", "human", "human", "pet">
            let h = 0
            let v = 0
            let u = 0
            let p = 0
            len.value = Number(homes.value) + Number(vehicles.value) + Number(humans.value) + Number(pets.value)
            subject_list.value = []
            subject_list.value = Array(len.value)
            for(let i=0; i < len.value; i++)
            {
                if(h < homes.value)
                {
                    h++
                    subject_list.value[i] = { 'collection': 'Home' }
                    continue
                }
                if ( v < vehicles.value ) 
                {
                    v++
                    subject_list.value[i] = { 'collection': 'Vehicle' }
                    continue
                }
                if ( u < humans.value ) 
                {
                    u++
                    subject_list.value[i] = {'collection': 'Human'}
                    continue
                }
                if ( p < pets.value ) 
                {
                    p++
                    subject_list.value[i] = {'collection': 'Pet'}
                    continue
                }
            }
            //console.log(subject_list.value)
            
            //console.log('index is ' + subject_list_index.value)
            showStartSummary.value = true
        }

        const startSetup = () => {
            showStartSummary.value = false
            showSetup.value = false
            if(len.value != 0){
                collection.value = subject_list.value[subject_list_index.value]
                showSubjectSetup.value = true
            }
            
        }


        const nextIndex = (tasks) => {
            showSubjectSetup.value = false
            //close setup modal

            //write the list of tasks created in subjectSetup into subject_list object
            subject_list.value[subject_list_index.value]['tasks'] = {}
            for(let task in tasks){
                //console.log("Adding the following to list of tasks.")
                //console.log(tasks[task])
                if (tasks[task].recommend_icon == 'remove'){
                    // If the icon is remove, that means that recommend was true and we want to add it to the list
                    subject_list.value[subject_list_index.value]['tasks'][task] = {
                        "cadence": tasks[task].cadence,
                        "shortDesc": tasks[task].shortDesc,
                        "collection": tasks[task].collection,
                        "duedate": tasks[task].duedate,
                        "notificationmethod": tasks[task].notificationmethod,
                        "notificationtime": tasks[task].notificationtime,
                        "taskid": tasks[task].taskid,
                        "subject": tasks[task].subject
                    }
                }
    

            }
            //print out the value of the object for the current index
            //console.log(subject_list.value[subject_list_index.value])
            
            subject_list_index.value++
            //increment the index so we can move to next subject
            //console.log('index: ' + subject_list_index.value + 'len: ' + len.value)
            // If index is equal to number of subjects, then move to summary page, else go to next item
            if (subject_list_index.value < len.value) { 
                
                //Show the modal window with the tasks
                collection.value = subject_list.value[subject_list_index.value]
                showSetup.value = false
                showSubjectSetup.value = true }
            else {
                showSetup.value = false
                showfinalsummary.value = true
            }
            
            
        }
        const previousIndex = () => {
            //hide Tasks list setup
            showSubjectSetup.value = false
            //hide final summary
            showfinalsummary.value = false
            //
            showSetup.value = true
            if (subject_list_index.value !== 0 ) { 
                subject_list_index.value-- 
                //console.log('index is ' + subject_list_index.value)
                collection.value = subject_list.value[subject_list_index.value]
                //continue to hide wizard page
                showSetup.value = false
                //Show the modal window with the tasks
                showSubjectSetup.value = true
            }
            
        }

        const finalConfirm = () => {
            //Run Payment function
            //After payment accepted, load all of the customer tasks into the DB
            //show "Submitting..." window
            showSubjectSetup.value = false
            showfinalsummary.value = false
            router.push("/")
        }
        

        return{houseselected, vehicleselected, humanselected, petselected, homes, vehicles, humans, pets, nextStep, showStartSummary, summary_message, showSubjectSetup, UUID, subject_list, subject_list_index, previousIndex, nextIndex, startSetup, collection, finalConfirm, showfinalsummary, showSetup}
    },
    components: {
        subjectSetup,
        showSummary
    }

}
</script>

<style scoped>

.material-icons { 
    font-size: 48px; 
    color: black;
}

select {
    background-color: white;
}

.startSummary {
    text-align: center;
    max-width: 400px;
    padding: 20px;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    z-index: 800;
    top: 20px;
}

ul {
    display: inline-block;
    text-align: left;
    list-style-type: none;
}

.dark {
    position: fixed;
    background: rgba(0,0,0,0.5);
    z-index: 900;
    width: 100%;
    height: 100%;
}

#done{
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

table {
    margin-left: auto;
    margin-right: auto;
    position: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}


table select {
    font-size: 24px;
}

.selected {
    color: #207EC6;
}
.selected .material-icons { 
    color: #207EC6;
}

.option {
    display: inline-block;
    padding: 30px;
    padding-inline: 60px;
    margin: 10px;
    border-radius: 10px;
    background: white;
    cursor: pointer;
}

.option:hover {
    z-index: 700;
    border: 1px solid;
    box-shadow: 6px 6px #888888;
}

.setup {
    position: absolute;
    top: 20vh;
    min-width: 300px;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 520px) {
        .setup {
            max-width: 100vw;
        }

        .taskForm {
            overflow-x: scroll;
        }


    }

@media (max-height: 520px) {
    .setup {
        top: 5vh;
        max-width: 100vw;
        max-height: 90vh;
        overflow-y: scroll;
    }

}

</style>