<template>
  <nav>
    <div id="nav">
        <router-link to="/">Home</router-link>
        <a :href="login_url" v-if="!loggedin" id="login">login</a>
        <div id="logoutdiv" v-if="loggedin">
          <tr>
          <td><a href="/.auth/logout" @click="logout_method">logout</a></td>
          <td><span class="material-icons" @click="openProfile" id="profileicon">person</span></td>
          </tr>
        </div>
        <footer class="footer">
          <div>
            <img src="./assets/Little-Reminders.png">
          </div>
        </footer>
    </div>
  </nav>
  <router-view/>
  <div v-if="showProfile">
    <Profile @closeProfile="openProfile" />
  </div>
</template>

<script>
import {ref} from 'vue'
import Profile from './components/Profile.vue'

export default {
    setup() {
        const user = ref({})
        const loggedin = ref(false)
        const showProfile = ref(false);
        const login_url = ref("")
        const nonce_number = ref("")

      
        //strictly for testing
        //user.value = {
        //      "identityProvider": "aad",
        //      "userId": "caa4f7ef2f604d52af9b04e877282b57",
        //      "userDetails": "austin.modelski@comcast.net",
        //      "userRoles": [
        //          "anonymous",
        //          "authenticated"
        //      ]
        //}

        // add a random number to the nonce value
        nonce_number.value = 12345
        login_url.value = "https://littlereminders.b2clogin.com/littlereminders.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_signupsignin1&client_id=d0ac88ad-a769-4964-98f8-22e0ac8df2ca&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.little-reminders.com%2Fcallback&scope=openid+email&response_type=id_token&prompt=login"
        //find user info
        const getUserInfo = () => {
          try {
            user.value = localStorage.getItem("UUID")
            //console.log(user.value)
            if (user.value){
              loggedin.value = true
            }
          }catch (err) {
            loggedin.value = false
          }
          
        }
        getUserInfo()
        
        const openProfile = () => {
            showProfile.value = !showProfile.value
        }
        const closeProfile = () => {
            showProfile.value = false
        }

        const logout_method = () => {
            localStorage.clear()
            loggedin.value = false
        }

        return { openProfile, showProfile, closeProfile, user, loggedin, login_url, logout_method};
    },
    components: { Profile }
}
</script>


<style>

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
  }

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}


.material-icons { 
  font-size: 36px;
  color: #207EC6;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  background: #A1ACBD;
  height: 100%;
  margin: 0;
  padding: 0;

}

nav {
  position: relative;
  top: 0;
  left: 0;
  display: inline;
  width: 100%;
  padding: 5px;
  margin-bottom: 50px;

}

nav a {
  top: 100%;
  float: left;
  font-weight: bold;
  color: #207EC6;
  border-style: hidden;
  border-radius: 5px;
  padding: 20px;
  margin-left: 10px;
}

#logoutdiv {
  display: inline;
  text-align: right;
  float: right;
  padding-right: 20px;
}

#profileicon {
  transform: translateY(-25%);
  cursor: pointer;
}

#login {
  display: inline;
  text-align: right;
  float: right;
  padding-right: 20px;
}

nav a.router-link-exact-active {
  color: white;
  background: #207EC6;
}

.error {
        color: #ff0062;
        margin-top: 10px;
        font-size: 0.8em;
        font-weight: bold;
    }

button {
  float: center;
  font-weight: bold;
  color: white;
  background: #207EC6;
  border-style: hidden;
  border-radius: 5px;
  padding: 10px;
  padding-inline: 20px;
  margin: 30px;
  cursor: pointer;
}

.footer {
  background-color: #A1ACBD;
  color: white;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
  position: fixed;
}

.footer img {
  height: 100px;
  padding: 20px;
}

.back {
  color: white; 
  background: #CA562A;
}

@media (max-height: 520px) {
  .footer {
    background-color: #A1ACBD;
    color: white;
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100%;
    position: fixed;
  }

  .footer img {
    display: none;
  }
}

@media (max-width: 520px) {
  .footer {
    background-color: #A1ACBD;
    color: white;
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100%;
    position: fixed;
  }

  .footer img {
    display: none;
  }
}

</style>
